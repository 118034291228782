import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Slide from "./Slide"

const settings = {
  dots: true,
  arrows: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: "ondemand",
}

const Slideshow = () => {
  const data = useStaticQuery(graphql`
    query sliderQuery {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "slide" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              url
              image {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Slider {...settings}>
      {data.allMarkdownRemark.edges.map(({ node }) => {
        const slide = node.frontmatter
        if (
          slide.image &&
          slide.image.childImageSharp &&
          slide.image.childImageSharp.fluid
        ) {
          return (
            <Slide
              image={slide.image.childImageSharp.fluid.src}
              title={slide.title}
              subTitle={slide.subtitle}
              url={slide.url}
            />
          )
        }
      })}
    </Slider>
  )
}

export default Slideshow
