import React from 'react'
import styled from 'styled-components'
import { Container } from '../../components/Theme';

const SlideWrapper = styled.div`
background-image: url(${props => props.image});
background-size: cover;
background-position: 50% 30%;
padding: 100px 0;
`;

const ContentContainer = styled.div`
background-color:rgba(0, 94, 107, 0.75);
color: white;
width: 100%;
border-radius: 500px;
padding: 30px 75px;
@media(min-width: 800px){
  width: 60%;
}
`;

const SlideTitle = styled.div`
text-transform: uppercase;
font-size: 1.25rem;
line-height: 1.25rem;
font-weight: bold;
margin: 15px 0;
@media(min-width: 800px){
  font-size: 1.75rem;
  line-height: 1.75rem;
}
`;

const SlideSubTitle = styled.div`
font-size: 1rem;
margin: 15px 0;
@media(min-width: 800px){
  font-size: 1.2rem;
}
`;

const ButtonContainer = styled.div`
text-align: right;
`;

const SlideButton = styled.a`
background-color:rgba(0, 94, 107, 1);
border: 1px solid white;
padding: 10px 32px;
border-radius:26px;
color: #fff !important;
text-decoration: none !important;
font-size: 0.75rem;
margin-bottom:0;
transition: background 0.25s, color 0.25s;
:hover {
  background-color: rgb(2, 137, 155);
}

@media(min-width: 800px){
  font-size: 1rem;
}

`;

const Slide = (props) => {
  return (
    <SlideWrapper image={props.image}>
      <Container>
        <ContentContainer>
          <SlideTitle>{props.title}</SlideTitle>
          {props.subTitle && <SlideSubTitle>{props.subTitle}</SlideSubTitle>}
          <ButtonContainer>
            {props.url && <SlideButton href={props.url}>Find out more</SlideButton>}
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </SlideWrapper>
  )
}

export default Slide
