import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Remarkable } from "remarkable"

import Layout from "../components/Layout"
import Slideshow from "../components/slideshow/Slideshow"
import Contact from "../components/Contact"
import { Container } from "../components/Theme"

const md = new Remarkable()

const BoxLayout = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  text-align: center;
  padding: 0px 20px;
  margin-top: 0;
  div {
    padding-bottom: 2em;
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.2em;
  }

  @media (min-width: 725px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const HomepagePanel1 = styled(Container)`
  text-align: center;
  color: #055f6a;
  font-size: 30px;
  line-height: 1.25em;
`

const HomepagePanel2 = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
`

const ContactWrapper = styled.div`
  background-color: rgba(0, 94, 107, 1);
  color: #fff;
  form,
  button {
    margin-bottom: 0;
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title="Solve technical problems without travelling. Firstline worker solutions from Metro."
        meta={[
          {
            name: "description",
            content: `Remote expert to digital processes, transform performance with augmented reality solutions from Metro.`,
          },
          { name: "keywords", content: `` },
          {
            name: "norton-safeweb-site-verification",
            content: `fwg6qczjgui03dv19tj66wt3nl4gyc4qp88ke3hnee0bun70vw0akv01k0umg8apai6ua0q41nsppwm969e5e06dhf3dsf52jbs2x4-xp3n9101c81ce1q42k17t-hqd`,
          },
        ]}
      />
      <Slideshow />
      <HomepagePanel1>
        <h1>Metro. Be there, Wear IT.</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: md.render(
              data.homeContent.edges[0].node.childMarkdownRemark.frontmatter
                .panel1
            ),
          }}
        />
      </HomepagePanel1>
      <div>
        <BoxLayout>
          <div>
            <Link to={`/your-challenges/`}>
              <Img
                fixed={data.yourChallenges.childImageSharp.fixed}
                alt={`Your Challenges`}
              />
              <h3>Your Challenges</h3>
            </Link>
          </div>
          <div>
            <Link to={`/solutions/`}>
              <Img
                fixed={data.communications.childImageSharp.fixed}
                alt={`Solutions`}
              />
              <h3>Solutions</h3>
            </Link>
          </div>
          <div>
            <Link to={`/services/`}>
              <Img
                fixed={data.services.childImageSharp.fixed}
                alt={`Services`}
              />
              <h3>Services</h3>
            </Link>
          </div>
        </BoxLayout>
      </div>
      <HomepagePanel2>
        <div
          dangerouslySetInnerHTML={{
            __html: md.render(
              data.homeContent.edges[0].node.childMarkdownRemark.frontmatter
                .panel2
            ),
          }}
        />
      </HomepagePanel2>
      <ContactWrapper>
        <Contact page={`Home Page`} />
      </ContactWrapper>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery {
    office365: file(relativePath: { regex: "/office-365_homepage-icon.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    dynamics365: file(
      relativePath: { regex: "/dynamics-365_homepage-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    azure: file(relativePath: { regex: "/azure_homepage-icon.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    yourChallenges: file(
      relativePath: { regex: "/your-challenges_homepage-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    businessProductivity: file(
      relativePath: { regex: "/business-productivity_homepage-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    businessSupport: file(
      relativePath: { regex: "/business-support_homepage-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    businessCloud: file(
      relativePath: { regex: "/business-cloud_homepage-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    communications: file(
      relativePath: { regex: "/communications_homepage_icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    services: file(relativePath: { regex: "/services_homepage-icon.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    welcomeYouTube: file(relativePath: { regex: "/welcome-video.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeContent: allFile(
      filter: { sourceInstanceName: { eq: "pages" }, name: { eq: "home" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              panel1
              panel2
            }
          }
        }
      }
    }
  }
`
export default IndexPage
